<template>
  <div class="outCard">
    <div v-show="$store.state.loadingState">
      <van-loading type="spinner" text-size="20" size="40px" vertical>正在加载中...</van-loading>
    </div>

    <div v-show="$store.state.emptyState">
      <van-empty image="network" description="网络连接异常,请检查更换网络后重试!" />
    </div>
  </div>
</template>

<script>
import { getNameUrl } from "@/network/apiFunc";
export default {
  name: "LoadView",
  data() {
    return {
      urlList: [],
      url: "",
      img: "",
      weChat: "",
    };
  },
  mounted() {
    document.title = "配音公司-广告配音制作-专业配音网站-宣传片配音软件-广电配音网";

    // 1.手动
    // this.$router.push({
    //   name: "home",
    //   query: {
    //     url: this.url,
    //     img: this.img,
    //     weChat: this.weChat,
    //   },
    // });
    // 2.自动
    let data = {
      name: "Create",
    };
    getNameUrl(data).then((res) => {
      if (res.success) {
        this.urlList = res.data;

        this.$router.push({
          name: "circle",
        });

        localStorage.setItem("urlList", this.urlList.url);
        console.log(this.urlList.name, this.urlList.url);
      } else {
        this.$notify({ type: "warning", message: "网络连接异常" });
      }
    });
  },
};
</script>

<style>
.outCard {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
